@font-face {
  font-family: 'SF Pro Display';
  src:  local("SF Pro Display Light"),
        local("SFProDisplay-Light"),
        url(./assets/fonts/SF-Pro-Display-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src:  local("SF Pro Display Regular"),
        local("SFProDisplay-Regular"),
        url(./assets/fonts/SF-Pro-Display-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src:  local("SF Pro Display Medium"),
        local("SFProDisplay-Medium"),
        url(./assets/fonts/SF-Pro-Display-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src:  local("SF Pro Display Semibold"),
        local("SFProDisplay-Semibold"),
        url(./assets/fonts/SF-Pro-Display-Semibold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src:  local("SF Pro Display Bold"),
        local("SFProDisplay-Bold"),
        url(./assets/fonts/SF-Pro-Display-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src:  local("SF Pro Text Light"),
        local("SFProText-Light"),
        url(./assets/fonts/SF-Pro-Text-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src:  local("SF Pro Text Regular"),
        local("SFProText-Regular"),
        url(./assets/fonts/SF-Pro-Text-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src:  local("SF Pro Text Medium"),
        local("SFProText-Medium"),
        url(./assets/fonts/SF-Pro-Text-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src:  local("SF Pro Text Semibold"),
        local("SFProText-Semibold"),
        url(./assets/fonts/SF-Pro-Text-Semibold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src:  local("SF Pro Text Bold"),
        local("SFProText-Bold"),
        url(./assets/fonts/SF-Pro-Text-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

